import React from "react"
//import { FaBeer } from 'react-icons/fa';
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageHeader, Banner } from '../utils'
import docsImg from '../images/bg/docsBg.jpg'

const DocsPage = () => (
  <Layout>
    <SEO title="Docs" keywords={[`project`, `team`, `people`]} />
    <PageHeader img={docsImg}>
      <Banner title="Documentation" subtitle="For those of you that like reading. (Aka the 'D' word)" />
    </PageHeader>
  </Layout>
)

export default DocsPage